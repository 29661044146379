import React, { useEffect, useContext } from 'react'
import css from "./Notification.module.css"
import { useGetNotifications, useDeleteNotification } from '../../React-Query/queriesAndMutations';
import { useInView } from 'react-intersection-observer';
import { AuthContext } from '../../AuthContext/AuthContext';
import { DEFAULTPROFILE } from "../../constants"
import moment from 'moment';
import Loader from '../../Components/Loader/Loader';
import Notification from "../../Assets/no-messages.svg"



const Notifications = () => {
    const { ref, inView } = useInView();
    const { user, isLoading } = useContext(AuthContext);
    const userId = user.user._id;
    const { mutate: deleteNotifcation } = useDeleteNotification();

    const DeleteNotifications = async (isDeleteAll, notificationType) => {
        try {
            deleteNotifcation({ isDeleteAll, userId, notificationType })
        } catch (error) {
            console.log(error);
        }
    }
    const {
        data,
        isLoading: isNotificationsLoading,
        fetchNextPage,
        hasNextPage
    } = useGetNotifications(userId);
    useEffect(() => {
        if (inView) fetchNextPage();
    }, [inView])


    if (isLoading || !user) {
        return <span>loading....</span>
    }
    const handleYesClick = () => {
        window.location.href = "https://play.google.com/store/apps/details?id=com.synchronia.lifestyle&pcampaignid=web_share";
    };

    return (
        <div className={css.container}>
            <div className={css.sections}>
                <div className={css.header}>
                    <h2>Your Notifications</h2>
                    <i class="uil uil-trash" onClick={() => DeleteNotifications(true, 0)}></i>
                </div>
                {
                    isNotificationsLoading ? <div className={css.notifications}>
                        <Loader />
                    </div> : <div className={css.notifications}>
                        {   data && data.length===0?<div className={css.notifi}>
                        <img className={css.noti} src={Notification} alt="message" />
                        </div>:
                            data && data.map((notification, index) => {
                                return <div className={css.notificationCard} key={index}>
                                    <div className={css.notiheader}>
                                        <div className={css.majorRow}>
                                            <img src={notification.notificationLogoUrl !== "" ? notification.notificationLogoUrl : DEFAULTPROFILE} alt="profile" />
                                            <div className={css.column}>
                                                <span>{notification.notificationTitleHeader}</span>
                                                <span>{moment(data.createdAt).fromNow()}</span>
                                            </div>
                                        </div>
                                        <i class="uil uil-trash" onClick={() => DeleteNotifications(false, notification.notificationType)}></i>
                                    </div>
                                    <div className={css.content}>
                                        <div className={css.Request}>
                                            <span>{notification.notificationContent}</span>
                                        </div>
                                        <button onClick={handleYesClick}>View in app</button>
                                    </div>
                                </div>
                            })
                        }
                                     {
                    hasNextPage ? <div className={css.loading} ref={ref}>
                        <span>Loading..</span>
                    </div> : <span></span>
                }
                    </div>
                }

            </div>
        </div>
    )
}

export default Notifications