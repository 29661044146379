import React from 'react'
import css from "./ProfilePages.module.css"
import EditProfile from '../../Pages/EditProfile/EditProfile'
import Support from '../../Pages/Support/Support'

const ProfilePages = ({ index, userdata }) => {
    return (
        <div className={css.container}>
            {
                index === 0 ? <EditProfile user={userdata} /> : <Support />
            }



        </div>
    )
}

export default ProfilePages
