import React, { useContext } from 'react'
import css from "./Login.module.css"
import AuthC from '../../Components/AuthComponent/authC'
import { AuthContext } from '../../AuthContext/AuthContext';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setIndex } from '../../Upload/actions';

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { index } = useSelector((state) => state.index);
  const { user, isLoading } = useContext(AuthContext);
  if (isLoading) {
    return (
      <div className={css.container}>
        <div className={css.sections}>
          {/* current authcard */}
          <div className={css.navbar}>
            <div className={css.header}><h2>Synchronia</h2> </div>
            <div className={css.row}>
              <div className={css.cards}></div>
              <div className={css.cards}></div>
              <div className={css.cards}></div>
            </div>
            <h2>Login</h2>
          </div>
          {/* auth card */}
          <div className={css.authSection}>
            <div className={css.authCard}>
              <AuthC />

            </div>
            <span>Don't have an Account? <span className={css.actionText}>Sign Up</span></span>
          </div>
          {/* footer */}
          <div className={css.footer}>
            <span>By clicking Login you agree to the <span className={css.actionText}>Terms and Conditions</span></span>
          </div>
        </div>
      </div>
    )
  }
  if (user && user.user && user.user._id) {
    navigate('/home');
  }
  const handleYesClick = () => {
    window.location.href = "https://synchroniahome.web.app/";
  };
  return (
    <div className={css.container}>
      <div className={css.sections}>
        {/* current authcard */}
        <div className={css.navbar}>
          <div className={css.header}><h2>Synchronia</h2> </div>
          <div className={css.row}>
            <div className={index === 0 ? css.activeCard : css.cards}></div>
            <div className={index === 1 ? css.activeCard : css.cards}></div>
            <div className={index === 2 ? css.activeCard : css.cards}></div>
          </div>
          <h2>{index === 0 ? "Login" : index === 1 ? "Sign Up" : "Forgot Password"}</h2>
        </div>
        {/* auth card */}
        <div className={css.authSection}>
          <div className={css.authCard}>
            <AuthC />

          </div>
          <span>{index === 0 ? "Don't have an Account? " : "Already have an Account? "}<span className={css.actionText} onClick={() => {
            if (index === 1) {
              dispatch(setIndex(0))
            } else {
              if (index === 2) {
                dispatch(setIndex(0))
              } else {
                dispatch(setIndex(1))
              }

            }
          }}>{
              index === 0 ? "Sign Up" : "Sign In"
            }</span></span>
        </div>
        {/* footer */}
        <div className={css.footer}>
          {
            index === 2 ? <span></span> : <span>By clicking {index === 0 ? "Login" : "Sign Up"} you agree to the <span className={css.actionText} onClick={handleYesClick} >Terms and Conditions</span></span>
          }
        </div>
      </div>
    </div>
  )
}

export default Login
