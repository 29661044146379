import React, { useContext } from 'react';
import css from "./Landing.module.css"
import Navbarone from '../../Components/navbarOne/navbarone'
import Landingwidget from '../../widgets/LandingWidget/landingwidget'
import Features from '../../widgets/FeaturesWidget/Features'
import Footer from '../../widgets/FooterWidget/Footer'
import { AuthContext } from '../../AuthContext/AuthContext';
import { useNavigate } from 'react-router-dom';

const Landing = () => {
    const navigate = useNavigate();
    const { user, isLoading } = useContext(AuthContext);
    if (isLoading) {
        return (
            <div className={css.container}>
                {/* Navbar */}
                <Navbarone />
                {/* landing page widget */}
                <Landingwidget />
                {/* features */}
                <Features />
                {/* Footer */}
                <Footer />


            </div>
        )
    }
    if (user) {
        navigate('/home');
    }
    return (
        <div className={css.container}>
            {/* Navbar */}
            <Navbarone />
            {/* landing page widget */}
            <Landingwidget />
            {/* features */}
            <Features />
            {/* Footer */}
            <Footer />


        </div>
    )
}

export default Landing
