import { useState } from "react";
import React from 'react'
import css from "./navbarone.module.css"
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setIndex } from "../../Upload/actions";
import { toast } from "react-toastify";
import axios from "axios";
import { Audio } from "react-loader-spinner";

const Navbarone = () => {
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [checkingRegion, setCheckingRegion] = useState(false);
    const [clickIndex, setClick] = useState(0);
    const dispatch = useDispatch();
    const toggleDrawer = () => {
        setIsDrawerOpen(!isDrawerOpen);
    };
    const navigate = useNavigate();
    const CheckAllowedRegion = async (index) => {
        try {
            setCheckingRegion(true);
            let position;
            try {
                position = await new Promise((resolve, reject) => {
                    navigator.geolocation.getCurrentPosition(resolve, reject);
                });
                const reponse = await axios.get(`https://synchronia.onrender.com/api/v1/new-user/check-allowed-region/${position.coords.latitude}/${position.coords.longitude}`)
                if (reponse.status === 200) {
                    dispatch(setIndex(index));
                    navigate('/login');
                    setCheckingRegion(false);
                }
            } catch (error) {
                setCheckingRegion(false);
                if (error.response.status === 404) {
                    toast("Sorry, no services nearby. but we're working to change that")
                } else {
                    toast(error.message);
                }

            }
        } catch (error) {
            setCheckingRegion(false);
            toast(error.message);
        }
    }

    return (
        <div className={css.container}>
            <div className={css.sections}>
                <h2 className={isDrawerOpen ? css.hideHeader : css.header}>Synchronia</h2>
                <div className={css.actions}>
                    <div className={css.mobileMenu} onClick={toggleDrawer}>
                        {
                            isDrawerOpen ? <i class="uil uil-multiply"></i> : <i class="uil uil-bars"></i>
                        }
                    </div>
                    <button onClick={() => {
                        setClick(0);
                        CheckAllowedRegion(0)
                    }}>{checkingRegion && clickIndex == 0 ? "just a sec" : "Login"}</button>
                    <button onClick={() => {
                        CheckAllowedRegion(1)
                        setClick(1);

                    }}>{checkingRegion && clickIndex == 1 ? "just a sec" : "Get Started"}</button>
                </div>
            </div>
            {isDrawerOpen && (
                <div className={css.drawer}>
                    <div className={css.mobileactions}>
                        <button onClick={() => {
                            CheckAllowedRegion(0)
                            setClick(0);

                        }}>{checkingRegion && clickIndex == 0 ? "just a sec" : "Login"}</button>
                        <button onClick={() => {
                            setClick(1);

                            CheckAllowedRegion(1)
                        }}>{checkingRegion && clickIndex == 1 ? "just a sec" : "Get Started"}</button>

                    </div>
                </div>
            )}
        </div>
    )
}

export default Navbarone
