import React, { useEffect, useState } from 'react'
import css from "./BsnReviews.module.css"
import axios from 'axios'
import { useGetBsnReviews } from '../../React-Query/queriesAndMutations';
import Rating from '../../Components/RatingCard/Rating';

const BsnReviews = ({ id }) => {
    const [stat, setStat] = useState(null);
    const [rating, setRating] = useState("0");
    const [loadingOne, setloadingOne] = useState(true);
    const {
        data,
        error,
        isLoading,
        fetchNextPage,
        hasNextPage
    } = useGetBsnReviews(id,rating);
    useEffect(() => {
        setloadingOne(true);
        const FetBsnStats = async () => {
            try {
                const response = await axios.get(`https://synchronia.onrender.com/api/v1/business/stats/${id}/reviews`);
                if (response.status === 200) {
                    setStat(response.data);
                    setloadingOne(false);
                }
            } catch (error) {
                console.log(error);
            }
        }
        FetBsnStats();
    }, [id])




    return (
        <div className={css.container}>
            <div className={css.leading}>
                {
                    !loadingOne ? <div className={css.header}>

                        <div className={css.defaultRating}>
                            <span>{stat.defaultRating}</span>
                            <span>{stat.reviewsCount} reviews</span>
                            <span>Averate Rating</span>
                        </div>

                        <div className={css.ratings}>

                            <div className={css.row}>
                                <div className={css.iconrow}>
                                    <span>1</span>
                                    ⭐
                                </div>
                                <span>{(stat.ratingsPercentages[1]) * 100}%</span>
                            </div>
                            <div className={css.row}>
                                <div className={css.iconrow}>
                                    <span>2</span>
                                    ⭐
                                </div>
                                <span>{(stat.ratingsPercentages[2]) * 100}%</span>
                            </div>
                            <div className={css.row}>
                                <div className={css.iconrow}>
                                    <span>3</span>
                                    ⭐
                                </div>
                                <span>{(stat.ratingsPercentages[3]) * 100}%</span>
                            </div>
                            <div className={css.row}>
                                <div className={css.iconrow}>
                                    <span>4</span>
                                    ⭐
                                </div>
                                <span>{(stat.ratingsPercentages[4]) * 100}%</span>
                            </div>
                            <div className={css.row}>
                                <div className={css.iconrow}>
                                    <span>5</span>
                                    ⭐
                                </div>
                                <span>{(stat.ratingsPercentages[5]) * 100}%</span>
                            </div>
                        </div>
                    </div> : <span></span>
                }
            </div>
            <div className={css.reviewsList}>
                <select value={rating} onChange={(e) => setRating(e.target.value)}>
                    <option value="0">All ratings⭐</option>
                    <option value="5">5⭐</option>
                    <option value="4">4⭐</option>
                    <option value="3">3⭐</option>
                    <option value="2">2⭐</option>
                    <option value="1">1⭐</option>

                </select>
                {
                    data && data.map((reviews, index) => {
                        return <Rating data={reviews} index={index} />
                    })
                }
            </div>
        </div>
    )
}

export default BsnReviews
