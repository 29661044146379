import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import css from "./authC.module.css"
import { useNavigate } from 'react-router-dom';
import { CreateAccount, SendPasswordResetEmail, SignInToAccount } from '../../React-Query/Api';
import { useDispatch, useSelector } from 'react-redux';
import { setIndex } from '../../Upload/actions';


const AuthC = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { index } = useSelector((state) => state.index);
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const { register, handleSubmit } = useForm();
    const [showPassword, setShowPassword] = useState(false);

    const onSubmit = async (data) => {
        setError("");
        setLoading(true);
        try {
            const response = await SignInToAccount(data.email, data.password);
            if (response.status === 200) {
                setLoading(false);
                navigate('/home')
            } else {
                setError("Invalid Credentials")
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
            if (error.code === 'auth/invalid-email' || error.code === 'auth/user-disabled' || error.code === 'auth/user-not-found') {
                setError('disabled user');
            } else if (error.code === 'auth/invalid-login-credentials') {
                setError('Invalid Credentials');
            } else {
                console.error('Firebase error:', error.message);
            }
        }


    };
    const onCreateSubmit = async (data) => {
        setError("");
        setLoading(true);

        try {
            const response = await CreateAccount(data.email, data.password, data.username);
            if (response.status === 200) {
                dispatch(setIndex(1));
                setLoading(false);
            } else {
                setError("Invalid Credentials")
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
            if (error.code === 'auth/invalid-email' || error.code === 'auth/user-disabled' || error.code === 'auth/user-not-found') {
                setError('disabled user');
            } else if (error.code === 'auth/invalid-login-credentials') {
                setError('Invalid Credentials');
            } else {
                console.error('Firebase error:', error.message);
            }
        }


    };
    const onForgotPasswordSubmit = async (data) => {
        setError("");
        setLoading(true);

        try {

            await SendPasswordResetEmail(data.email);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.error('Firebase error:', error.message);
        }


    };


    const renderForm = () => {
        switch (index) {
            case 0:
                return (
                    <form className={css.loginForms} onSubmit={handleSubmit(onSubmit)}>
                        <div className={css.inputWithIcon}>
                            <i class="uil uil-envelope"></i>
                            <input type="email" placeholder="Email" {...register('email', { required: true })} />
                        </div>
                        <div className={css.inputWithIcon}>
                            <i class="uil uil-lock"></i>
                            <input
                                type={showPassword ? 'text' : 'password'}
                                placeholder="Password"
                                {...register('password', { required: true })}
                            />
                            <span className={css.toggleIcon} onClick={() => setShowPassword(!showPassword)}>
                                {showPassword ? <i class="uil uil-eye-slash"></i> : <i class="uil uil-eye"></i>}
                            </span>
                        </div>
                        <h2 className={css.forgotPassword} onClick={() => dispatch(setIndex(2))}>Forgot Password ?</h2>
                        <span className={css.errormessage}>{error}</span>
                        <button type="submit">{loading ? "Just a sec..." : "Login"}</button>
                    </form>
                );
            case 1: // Signup form
                return (
                    <form className={css.loginForms} onSubmit={handleSubmit(onCreateSubmit)}>
                        <div className={css.inputWithIcon}>
                            <i class="uil uil-user"></i>
                            <input type="text" placeholder="Username" {...register('username', { required: true })} />
                        </div>
                        <div className={css.inputWithIcon}>
                            <i class="uil uil-envelope"></i>
                            <input type="email" placeholder="Email" {...register('email', { required: true })} />
                        </div>
                        <div className={css.inputWithIcon}>
                            <i class="uil uil-lock"></i>
                            <input
                                type={showPassword ? 'text' : 'password'}
                                placeholder="Password"
                                {...register('password', { required: true })}
                            />
                            <span className={css.toggleIcon} onClick={() => setShowPassword(!showPassword)}>
                                {showPassword ? <i class="uil uil-eye-slash"></i> : <i class="uil uil-eye"></i>}
                            </span>
                        </div>
                        <span>{error}</span>
                        <button type="submit">{loading ? "Just a sec..." : "Sign up"}</button>
                    </form>
                );
            case 2: // Forgot password form
                return (
                    <form className={css.loginForms} onSubmit={handleSubmit(onForgotPasswordSubmit)}>
                        <span>Enter your email for a password <br />Reset</span>
                        <div className={css.inputWithIcon}>
                            <i class="uil uil-envelope"></i>
                            <input type="email" placeholder="Email" {...register('email', { required: true })} />
                        </div>
                        <span>{error}</span>
                        <button type="submit">{loading ? "Just a sec..." : "Submit"}</button>
                    </form>
                );
            default:
                return null;
        }
    };
    return (
        <div>
            {renderForm()}
        </div>
    )
}

export default AuthC
