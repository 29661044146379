import React, { useState } from 'react'
import css from "./Card.module.css"
import Carosell from '../../Components/Carousell/carosell';
import { groupNumber } from "../../Data/index"
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useLikePost } from '../../React-Query/queriesAndMutations';

const Card = ({ data, onImagesClicked, isBusiness, userId }) => {
    const navigate = useNavigate();
    const [showMore, setShowMore] = useState(false);
    const likedSet = new Set(data.isLikeCount.length === 0 ? [] : data.isLikeCount);
    const isLiked = likedSet.has(userId);
    const likeCount = likedSet.size;
    const handleNavigate = () => {
        navigate(`/business/${data.bsnId}`);
    }
    const { mutate: likePostMutation } = useLikePost();
    const LikeDeal = async (isLike) => {
        const dealId = data._id;
        try {
            likePostMutation({ dealId, userId, isLike });
        } catch (error) {
            console.log(error);
        }
    }

    const AddViewCount = async () => {
        setShowMore(true);
        try {
            const requestBody = {
                dealId: data._id,
                userId
            }
            await axios.post(`https://synchronia.onrender.com/api/v1/deals/add-viewed-count`, requestBody)
        } catch (error) {
            console.log(error);
        }
    }



    return (
        <div className={css.card}>
            {/* header */}
            <div className={css.header}>
                <div className={css.row}>
                    <img src={data.logo} alt="logo" />
                    <div className={css.column}>
                        <span>{data.title}</span>
                        <span>{data.dealLocation}</span>
                    </div>
                </div>
                {
                    !isBusiness ? <i class="uil uil-ellipsis-h" onClick={handleNavigate}></i> : <span></span>
                }

            </div>
            {/* images */}
            <div className={css.carousell}>
                <Carosell images={data.images} onClicked={onImagesClicked} />
            </div>
            {/* likes and description */}
            <div className={css.likeandDesc}>
                <div className={css.likerow}>
                    <div className={css.like2row}>
                        <div className={css.stat}>
                            {
                                isLiked ? <span onClick={() => LikeDeal(false)}>❤️</span> : <i class="uil uil-heart" onClick={() => LikeDeal(true)} ></i>
                            }
                            <span> {likeCount}</span>
                        </div>
                        <div className={css.stat}>
                        </div>
                    </div>
                    {
                        data.isgroupBuy === 0 ? <i class="uil uil-user"></i> : <i class="uil uil-users-alt"></i>
                    }

                </div>
                <div className={css.description}>
                    <div className={css.adTitle}>
                        {data.adTitle}
                        {showMore ? <span onClick={() => setShowMore(false)}>Show less</span> : <span onClick={AddViewCount}>Show more</span>}
                    </div>
                    <div className={!showMore ? css.lessDescription : css.moreDescription}>
                        <div className={css.participants}>
                            {data && data.isgroupBuy === 1 ? (
                                <div className={css.groupTemplate}>
                                    <div className={css.row}>
                                        <i class="uil uil-users-alt"></i>
                                        <span>{data.noOfParticipants}</span>
                                    </div>
                                    <div className={css.row}>
                                        <i class="uil uil-users-alt"></i>
                                        <span>{groupNumber(data.price)} KSH</span>
                                    </div>
                                    <div className={css.row}>
                                        <i class="uil uil-user"></i>
                                        <span>
                                            {groupNumber(data.pricePerParticipants)} KSH
                                        </span>
                                    </div>
                                </div>
                            ) : (
                                <div className={css.personalTemplate}>
                                    <div className={css.row}>
                                        <i class="uil uil-user"></i>
                                        <span>{groupNumber(data.price)}KSH</span>
                                    </div>
                                </div>
                            )}
                        </div>
                        <span>{data.dealDescription}</span>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default Card
