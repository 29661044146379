import React from 'react'
import css from "./BusinessInfo.module.css"

const BusinessInfo = ({ data }) => {
    if (!data) {
        return <span>loading...</span>
    }
    function redirectToWhatsAppAndMaps(ismap, phoneNumber, whatsappMessage, latitude, longitude) {
        if (!ismap) {
            const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(whatsappMessage)}`;
            window.open(whatsappUrl, '_blank');
        } else {
            if(latitude && longitude){
                const mapsUrl = `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`;
                window.open(mapsUrl, '_blank');
            }
            
        }



    }
    return (
        <div className={css.container}>
            <div className={css.header}>
                <div className={css.majorRow}>
                    <img src={data.logo} alt="profile" />
                    <div className={css.column}>
                        <span>{data.title}</span>
                        <span>{data.City}</span>
                    </div>
                </div>
                <div className={css.actions}>
                    <i class="uil uil-location-arrow" onClick={() => redirectToWhatsAppAndMaps(true, "", "", data.Location.coordinates[1], data.Location.coordinates[0])}></i>
                    <i class="uil uil-whatsapp" onClick={() => redirectToWhatsAppAndMaps(false, data.whatsappNumber, 'Hello there am reaching out from Synchronia....', "", "")}></i>
                </div>
            </div>
            <div className={css.serviceHours}>
                {
                    data && data.serviceHours.map((hour, index) => {
                        if (hour.isClosed) {
                            return <div className={css.hours} key={index}>
                                <span>Closed on {hour.day}</span>
                            </div>
                        } else {
                            return <div className={css.hours}>
                                <span>{hour.day}</span>
                                <div className={css.time}>
                                    <span>{hour.startTime}</span>
                                    <span>to</span>
                                    <span>{hour.endTime}</span>
                                </div>

                            </div>
                        }
                    })
                }
            </div>
        </div>
    )
}

export default BusinessInfo
