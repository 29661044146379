import React, { useState, useContext } from 'react'
import css from "./Update.module.css"
import { useParams } from 'react-router-dom'
import { AuthContext } from '../../AuthContext/AuthContext';
import { updateUser } from '../../React-Query/Api';


const Update = () => {
    const { user, isLoading } = useContext(AuthContext);
    const profileImage = user.user.profileimage;
    const { id, uid } = useParams();
    const [image, setImage] = useState(null);
    const [formData, setFormData] = useState({
        username: user.user.username,
        profession: user.user.profession,
        about: user.user.about
    });
    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const validationErrors = validateForm(formData);
        setErrors(validationErrors);
        if (Object.keys(validationErrors).length === 0) {
            updateUser(id, uid, profileImage, image, formData.username, formData.profession, formData.about);
        }
    };

    const validateForm = (data) => {
        let errors = {};
        if (!data.username.trim()) {
            errors.username = 'Username is required';
        }
        if (!data.profession.trim()) {
            errors.profession = 'Profession is required';
        }
        if (!data.about.trim()) {
            errors.about = 'About is required';
        }
        return errors;
    };
    const handleLogoChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setImage(file);
        }
    };
    if (!user) {
        return <span>Loading....</span>
    }

    return (
        <div className={css.container}>
            <div className={css.sections}>
                <div className={css.image}>
                    {
                        image === null ? <img src={profileImage} alt="" /> : <img src={URL.createObjectURL(image)} alt="" />
                    }
                    <label htmlFor="logo-input" className={css.uploadLabel}>
                        <i className="uil uil-camera-plus"></i>
                    </label>
                    <input
                        type="file"
                        id="logo-input"
                        accept="image/*"
                        onChange={handleLogoChange}
                        style={{ display: 'none' }}
                    />
                </div>
                <div className={css.forms}>
                    <form onSubmit={handleSubmit}>
                        <div className={css.formGroup}>
                            <label htmlFor="username">Username</label>
                            <input
                                type="text"
                                id="username"
                                name="username"
                                placeholder={user.user.username}
                                value={formData.username}
                                onChange={handleChange}
                            />
                            {errors.username && <span className={css.error}>{errors.username}</span>}
                        </div>
                        <div className={css.formGroup}>
                            <label htmlFor="profession">Profession</label>
                            <input
                                type="text"
                                id="profession"
                                name="profession"
                                placeholder={user.user.profession}
                                value={formData.profession}
                                onChange={handleChange}
                            />
                            {errors.profession && <span className={css.error}>{errors.profession}</span>}
                        </div>
                        <div className={css.formGroup}>
                            <label htmlFor="about">About</label>
                            <textarea
                                id="about"
                                name="about"
                                placeholder={user.user.about !== "" ? user.user.about : "Hey there am on Synchronia"}
                                value={formData.about}
                                onChange={handleChange}
                            ></textarea>
                            {errors.about && <span className={css.error}>{errors.about}</span>}
                        </div>
                        <button type="submit">Submit</button>
                    </form>
                </div>
            </div>
        </div>
    );
};


export default Update
