import React from 'react'
import css from "./ProfileRoute.module.css"

const ProfileRoute = ({ icon, title, subtitle ,onClick }) => {
    return (
        <div className={css.container} onClick={onClick}>
            <div className={css.rowOne}>
                <div className={css.avator}>
                    {icon}
                </div>
                <div className={css.column}>
                    <span>{title}</span>
                    <span>{subtitle}</span>
                </div>
            </div>
            <div className={css.navigation}>
                <i class="uil uil-angle-right-b"></i>
            </div>
        </div>
    )
}

export default ProfileRoute
