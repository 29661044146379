import React from 'react'
import css from "./Features.module.css"
import "react-multi-carousel/lib/styles.css";
import Carousel from 'react-multi-carousel';
import FeatureCard from '../../Components/FeatureCard/FeatureCard';

const Features = () => {
    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            items: 1,
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 1,
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1,
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
        },
    };
    const Features = [
        <FeatureCard description={'Explore services from beloved brands nearby, with smart filters for location, pricing, and participants. Customize your experience effortlessly.'} imagUrl={'https://firebasestorage.googleapis.com/v0/b/synchronia-ke.appspot.com/o/introdata%2FScreenshot_1711234098.png?alt=media&token=98ba7713-fe3e-497f-b94f-209d057fdc0f'} />,
        <FeatureCard description={"Immerse yourself in your favorite brand's world. Explore exclusive updates, reviews, location details, and direct communication via WhatsApp."} imagUrl={'https://firebasestorage.googleapis.com/v0/b/synchronia-ke.appspot.com/o/introdata%2Fbs_focus.png?alt=media&token=f51e7323-7802-4efc-addf-e9af49bd80d7'} />,
        <FeatureCard description={'Save your favorite posts for later and never miss out on what matters most. Organize, revisit, and enjoy at your convenience.'} imagUrl={'https://firebasestorage.googleapis.com/v0/b/synchronia-ke.appspot.com/o/introdata%2FScreenshot_1711234236.png?alt=media&token=a3c91f39-c7cc-4e7a-9dbc-98fed17a0962'} />,
        <FeatureCard description={'Preserve your favorites with love and care. Each saved post is a tribute to our commitment to enhancing your experience'} imagUrl={'https://firebasestorage.googleapis.com/v0/b/synchronia-ke.appspot.com/o/introdata%2FScreenshot_1710067229.png?alt=media&token=be0ec29c-a0a9-4a6d-9071-4e02c003cd48'} />
    ];
    return (
        <div className={css.container}>
            <div className={css.sections}>
                <h2>WITH SYCHRONIA YOU CAN</h2>
                <div className={css.couroselll}>
                    <Carousel responsive={responsive}>
                        {
                            Features.map((feature, index) => {
                                return <div className={css.featureContainer} key={index}>
                                    {feature}
                                </div>
                            })
                        }
                    </Carousel>
                </div>
            </div>
        </div>
    )
}

export default Features
