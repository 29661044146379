import React from 'react'
import css from "./FavCard.module.css"

const FavCard = ({data}) => {
    return (
        <div className={css.container}>
            <div className={css.row}>
                <div className={css.Column}>
                    <span>{data.title}</span>
                    <span>{data.adTitle}</span>
                </div>
                ❤️
            </div>
        </div>
    )
}

export default FavCard
