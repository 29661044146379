import React, { useState, useEffect, useContext } from 'react'
import css from "./Business.module.css"
import { useParams } from 'react-router-dom'
import { filtersCategory, sortingOptions } from '../../Data'
import Card from '../../Components/ActivitiesCard/Card';
import { useGetBusinessPosts } from '../../React-Query/queriesAndMutations';
import { useInView } from 'react-intersection-observer';
import useDebounce from "../../widgets/Debounce/Debounce";
import Rodal from "rodal";
import "rodal/lib/rodal.css";
import FSizeCarosell from '../../Components/FullSizeCarosell/FSizeCarosell';
import axios from 'axios';
import BsnReviews from '../../widgets/Reviews/BsnReviews';
import BusinessInfo from '../../widgets/Business/BusinessInfo';
import { AuthContext } from '../../AuthContext/AuthContext';
import Loader from '../../Components/Loader/Loader';
const Business = () => {
    const { id } = useParams();
    const { user, isLoading: isUserloading } = useContext(AuthContext);
    const { ref, inView } = useInView();
    const [business, setBusiness] = useState(null);
    const [viewClass, setViewClasss] = useState(css.filters);
    const [bsnLoading, setBsnLoading] = useState(true);
    const [tabIndex, setTabIndex] = useState(0);
    const [modal, setModal] = useState({ visible: false });
    const [category, setCategory] = useState("All");
    const [sorting, setsorting] = useState("Recommended First");
    const [price, setPrice] = useState("0");
    const [participants, setParticipants] = useState("0");
    const [showFilters, setFilters] = useState(false);
    const deboucedCategory = useDebounce(category, 500)
    const deboucedPrice = useDebounce(price, 500)
    const deboucedPersons = useDebounce(participants, 500)
    const deboucedSorting = useDebounce(sorting, 500)
    const customStyles = {
        background: "var(--scaffoldcolor)",
        padding: "20px ",
        width: "90%",
        top: "-3rem",
        height: "fit-content",
        maxWidth: "90%",
    };
    const {
        data,
        error,
        isLoading,
        fetchNextPage,
        hasNextPage
    } = useGetBusinessPosts(id, deboucedCategory, deboucedPrice, deboucedPersons, deboucedSorting);

    useEffect(() => {
        setBsnLoading(true);
        const FetchBusiness = async () => {
            try {
                const response = await axios.get(`https://synchronia.onrender.com/api/v1/business/get-by-id/${id}`);
                if (response.status === 200) {
                    setBusiness(response.data.foundbusiness);
                    setBsnLoading(false);
                }
            } catch (error) {
                console.log(error);
            }
        }
        FetchBusiness();
    }, [id])

    useEffect(() => {
        if (inView) fetchNextPage();
    }, [inView])

    if (bsnLoading) {
        return <Loader />
    }
    return (
        <div className={css.container}>
            {
                isLoading ? <div className={css.cards}>
                    <Loader />
                </div> : <div className={css.cards}>
                    {
                        data && data.map((card, index) => {
                            return <div className={css.cardItem} key={index}>
                                <Card isBusiness={true} userId={user.user._id} data={card} onImagesClicked={() => setModal({ visible: true, images: card.images })} />
                            </div>
                        })
                    }
                    {
                        hasNextPage ? <div className={css.loading} ref={ref}>
                            <span>Loading..</span>
                        </div> : <span></span>
                    }
                    <Rodal
                        customStyles={customStyles}
                        visible={modal.visible}
                        onClose={() => {
                            setModal({ visible: false });
                        }}
                    >

                        <FSizeCarosell images={modal.images ?? []} />

                    </Rodal>
                </div>
            }

            <div className={showFilters ? css.leftSideFilters : css.leftSide}>
                <div className={viewClass === css.filters ? css.boutHide : css.aboutBusiness}>
                    <div className={css.header}>
                        <span onClick={() => setTabIndex(0)} className={tabIndex === 0 ? css.active : css.inactive}>Business</span>
                        <span onClick={() => setTabIndex(1)} className={tabIndex === 1 ? css.active : css.inactive}>Reviews</span>
                    </div>
                    <div className={css.content}>
                        {
                            tabIndex === 0 ? <BusinessInfo data={business && business} /> : <BsnReviews id={id} />
                        }
                    </div>
                </div>

                <div className={viewClass}>
                    <span>Personalized perfection. Let's find your ideal deal together</span>
                    {/* Categories */}
                    <i class={viewClass === css.filters ? "uil uil-angle-up" : "uil uil-angle-down"} onClick={() => {
                        if (viewClass === css.filters) {
                            setViewClasss(css.fullViewFilters)
                        } else {
                            setViewClasss(css.filters)
                        }
                    }}></i>
                    <div className={css.column}>
                        <label htmlFor="">Select category</label>
                        <select value={category} onChange={(e) => setCategory(e.target.value)}>
                            {
                                filtersCategory.map((category, index) => {
                                    return <option value={category} key={index}>{category}</option>
                                })
                            }
                        </select>
                    </div>
                    {/* recommended first */}
                    <div className={css.column}>
                        <label htmlFor="">Sort by recommended</label>
                        <select value={sorting} onChange={(e) => setsorting(e.target.value)}>
                            {
                                sortingOptions.map((category, index) => {
                                    return <option value={category} key={index}>{category}</option>
                                })
                            }
                        </select>
                    </div>
                    {/* pricing */}
                    <div className={css.column}>
                        <label htmlFor="Filter by price">Filter by price</label>
                        <input type="number" placeholder='Max 50,000 KSH' max={50000} value={price} onChange={(e) => setPrice(e.target.value)} />
                    </div>
                    {/* Participants */}
                    <div className={css.column}>
                        <label htmlFor="">Filter by participants</label>
                        <input type="number" placeholder='Max 10' max={10} value={participants} onChange={(e) => setParticipants(e.target.value)} />
                    </div>
                    {/* Apply Filters Button */}
                    <button onClick={() => {
                        setCategory("All");
                        setPrice("0");
                        setParticipants("0");
                        setsorting('Recommended First');
                    }}>Reset Filters</button>
                </div>

            </div>
            <div className={css.mobileFiters} onClick={() => {
                setFilters(!showFilters);

            }}>
                {
                    showFilters ? <span class="material-symbols-outlined">
                        close
                    </span> : <span class="material-symbols-outlined">
                        more_horiz
                    </span>
                }

            </div>
        </div>
    )
}

export default Business
