import React from 'react'
import css from "./Rating.module.css"
import {DEFAULTPROFILE} from "../../constants"

const Rating = ({ data, index }) => {
    return (
        <div className={css.container} key={index}>
            <div className={css.mainRow}>
                <img src={data.profileImage !== "" ? data.profileImage : DEFAULTPROFILE} alt="fr" />
                <div className={css.column}>
                    <span>{data.username}</span>
                    <span>{data.rating} ⭐</span>
                    <span>{data.review}</span>
                </div>
    
            </div>
        </div>
    )
}

export default Rating
