import React, { useState } from 'react';
import css from "./carosell.module.css";

const Carosell = ({images,onClicked}) => {
    const [currentImageIndex, setCurrentImageIndex] = useState(0);


    const goToNextSlide = () => {
        setCurrentImageIndex((prevIndex) =>
            prevIndex === images.length - 1 ? 0 : prevIndex + 1
        );
    };

    const goToPrevSlide = () => {
        setCurrentImageIndex((prevIndex) =>
            prevIndex === 0 ? images.length - 1 : prevIndex - 1
        );
    };

    return (
        <div className={css.carousel} >
            <div className={css.carouselContainer}>
                <div className={css.carouselTrack} style={{ transform: `translateX(-${currentImageIndex * 100}%)` }}>
                    {images && images.map((imageUrl, index) => (
                        <img
                            key={index}
                            src={imageUrl.url}
                            onClick={onClicked}
                            alt={`Slide ${index + 1}`}
                            className={css.carouselImage}
                        />
                    ))}
                </div>
            </div>
            <button onClick={goToPrevSlide} className={images.length===1?css.hidebtn: css.prevButton}>
            <i class="uil uil-angle-left-b"></i>
            </button>
            <button onClick={goToNextSlide} className={images.length===1?css.hidebtn:css.nextButton}>
            <i class="uil uil-angle-right-b"></i>
            </button>

        </div>
    );
};

export default Carosell;
