import React from 'react'
import css from "./FeatureCard.module.css"

const FeatureCard = ({ imagUrl, description }) => {
    return (
        <div className={css.container}>
            <img src={imagUrl} alt="url" />
            <div className={css.explanantion}>
                <span>{description}</span>
            </div>
        </div>
    )
}

export default FeatureCard
