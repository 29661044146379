import React, { useState, useEffect, useContext } from 'react'
import css from "./Activities.module.css"
import { filtersCategory, sortingOptions, distanceOptions } from '../../Data'
import Card from '../../Components/ActivitiesCard/Card';
import { useGetPosts } from '../../React-Query/queriesAndMutations';
import { useInView } from 'react-intersection-observer';
import useDebounce from "../../widgets/Debounce/Debounce";
import Rodal from "rodal";
import "rodal/lib/rodal.css";
import FSizeCarosell from '../../Components/FullSizeCarosell/FSizeCarosell';
import { AuthContext } from '../../AuthContext/AuthContext';
import Loader from '../../Components/Loader/Loader';

const Activities = () => {
    const { user, isLoading } = useContext(AuthContext);
    const { ref, inView } = useInView();
    const [modal, setModal] = useState({ visible: false });
    const [category, setCategory] = useState("All");
    const [sorting, setsorting] = useState("Recommended First");
    const [price, setPrice] = useState("0");
    const [participants, setParticipants] = useState("0");
    const [distance, setDistance] = useState(distanceOptions[0]);
    const [showFilters, setFilters] = useState(false);
    const deboucedCategory = useDebounce(category, 500)
    const deboucedPrice = useDebounce(price, 500)
    const deboucedPersons = useDebounce(participants, 500)
    const deboucedDistance = useDebounce(distance, 500)
    const deboucedSorting = useDebounce(sorting, 500)
    const customStyles = {
        background: "var(--scaffoldcolor)",
        padding: "20px ",
        width: "90%",
        top: "-3rem",
        height: "fit-content",
        maxWidth: "90%",
    };
    const {
        data,
        error,
        isLoading: postLaoding,
        fetchNextPage,
        hasNextPage
    } = useGetPosts(1, deboucedCategory, deboucedPrice, deboucedPersons, deboucedDistance, deboucedSorting);

    useEffect(() => {
        if (inView) fetchNextPage();
    }, [inView])
    if (isLoading || !user) {
        return <Loader />
    }

    const userId = user && user.user && user.user._id ? user.user._id : "123456789";
    return (
        <div className={css.container}>
            {postLaoding ? <div className={css.cards}><Loader /></div> : <div className={css.cards}>
                {data && data.length === 0 ? <div className={css.loadering}>
                    <span>We are working hard to add posts. check again in a bit.or increase visibility filter</span>
                </div> :
                    data && data.map((card, index) => {
                        return <div className={css.cardItem} key={index}>
                            <Card  userId={userId} data={card} onImagesClicked={() => setModal({ visible: true, images: card.images })} />
                        </div>
                    })
                }
                {
                    hasNextPage ? <div className={css.loading} ref={ref}>
                        <span>Loading..</span>
                    </div> : <span></span>
                }
                <Rodal
                    customStyles={customStyles}
                    visible={modal.visible}
                    onClose={() => {
                        setModal({ visible: false });
                    }}
                >

                    <FSizeCarosell images={modal.images ?? []} />

                </Rodal>
            </div>
            }

            <div className={showFilters ? css.leftSideFilters : css.leftSide}>
                <span>Personalized perfection. Let's find your ideal deal together</span>
                {/* Categories */}
                <div className={css.column}>
                    <label htmlFor="">Select category</label>
                    <select value={category} onChange={(e) => setCategory(e.target.value)}>
                        {
                            filtersCategory.map((category, index) => {
                                return <option value={category} key={index}>{category}</option>
                            })
                        }
                    </select>
                </div>
                {/* recommended first */}
                <div className={css.column}>
                    <label htmlFor="">Sort by recommended</label>
                    <select value={sorting} onChange={(e) => setsorting(e.target.value)}>
                        {
                            sortingOptions.map((category, index) => {
                                return <option value={category} key={index}>{index===0?category:index===1?"Lowest Price First":"Highest Price First"}</option>
                            })
                        }
                    </select>
                </div>
                {/* Distance */}
                <div className={css.column}>
                    <label htmlFor="">Visibility distance km</label>
                    <select value={distance} onChange={(e) => setDistance(e.target.value)}>
                        {
                            distanceOptions.map((distancee, index) => {
                                return <option value={distancee} key={index}>{distancee / 1000}</option>
                            })
                        }
                    </select>
                </div>
                {/* pricing */}
                <div className={css.column}>
                    <label htmlFor="Filter by price">Filter by price</label>
                    <input type="number" placeholder='Max 50,000 KSH' max={50000} value={price} onChange={(e) => setPrice(e.target.value)} />
                </div>
                {/* Participants */}
                <div className={css.column}>
                    <label htmlFor="">Filter by participants</label>
                    <input type="number" placeholder='Max 10' max={10} value={participants} onChange={(e) => setParticipants(e.target.value)} />
                </div>

                {/* Apply Filters Button */}
                <button onClick={() => {
                    setDistance(6000);
                    setCategory("All");
                    setPrice("0");
                    setParticipants("0");
                    setsorting('Recommended First');
                }}>Reset Filters</button>
            </div>
            <div className={css.mobileFiters} onClick={() => {
                setFilters(!showFilters);

            }}>
                {
                    showFilters ? <span class="material-symbols-outlined">
                        close
                    </span> : <span class="material-symbols-outlined">
                        tune
                    </span>
                }

            </div>
        </div>
    )
}

export default Activities
