export const page_links = [
    {
        icon: <i class="uil uil-estate"></i>,
        title: "home",
    },

    {
        icon: <i class="uil uil-compass"></i>,
        title: "Explore",
    },
    {
        icon: <i class="uil uil-heart"></i>,
        title: "Favorites",
    },

    {
        icon: <i class="uil uil-users-alt"></i>,
        title: "Community",
    },
    {
        icon: <i class="uil uil-bell"></i>,
        title: "Notifications",
    },
];
export const groupNumber = (number) => {
    if (number === undefined) {
        return "";
    }
    return parseFloat(number.toFixed(2)).toLocaleString("en", {
        useGrouping: true,
    });
};
export const filtersCategory = [
    "All",
    "Culture - Arts",
    "Fine Dining",
    "Fitness",
    "Fun - Games",
    "Nightlife",
    "Outdoor",
    "Photography",
    "Treats",
    "Travel - Tours",
    "Wellness"
];
export const sortingOptions = [
    "Recommended First",
    "Highest Price First",
    "Lowest Price First",
];
export const distanceOptions = [
    6000,
    12000,
    24000,
    48000,
    50000,
];