import React, { useState, useContext, useEffect } from 'react'
import css from "./Profile.module.css"
import ProfileRoute from '../../Components/ProfileRoute/ProfileRoute';
import ProfilePages from '../../Components/ProfileComponent/ProfilePages';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../AuthContext/AuthContext'

const Profile = () => {
    const { user, isLoading } = useContext(AuthContext);
    const [pageIndex, setPageIndex] = useState(0);
    const navigate = useNavigate();
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    useEffect(() => {
        const handleResize = () => {
            const newWidth = window.innerWidth;
            setWindowWidth(newWidth);
        };
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    if (isLoading) {
        return <span>loading...</span>
    }

    const handleNavigation = (index) => {
        if (windowWidth < 1200) {
            if (user) {
                if (index === 0) {
                    navigate(`/profile/${user.user.username}`)
                } else if (index === 1) {
                    navigate(`/profile/friends`)
                } else if (index === 2) {
                    navigate(`/profile/posts`)
                } else {
                    navigate(`/profile/support`)
                }
            }

        } else {
            setPageIndex(index);

        }
    }


    return (<div className={css.container}>
        <div className={css.cards}>
            <ProfilePages index={pageIndex} userdata={user.user} />
        </div>

        <div className={css.leftSide}>
            {/* favorite_lists */}
            <div className={css.favlists}>
                <ProfileRoute onClick={() => {
                    handleNavigation(0);
                }} title={`${user.user.username}`} subtitle={'Personal Info'} icon={<i class="uil uil-user"></i>} />
                <ProfileRoute onClick={() => {
                    handleNavigation(3);
                }} title={'Support'} subtitle={'Customer Support'} icon={<i class="uil uil-comment-question"></i>} />
                <ProfileRoute title={'Delete you account'} subtitle={'Clear all your data'} icon={<i class="uil uil-trash"></i>} />
            </div>
        </div>
    </div>);

}

export default Profile
