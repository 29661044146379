import { useMutation, useQueryClient, useInfiniteQuery } from "@tanstack/react-query";
import { getInfinitePosts, likePost, deleteNotification, getInfiniteNotifications, getInfiniteBsnReviews, getInfiniteBusinessPosts, getInfiniteFavorite, getInfiniteExploreResults, getInfiniteUsers, getInfiniteExploreNonSearchResults } from "./Api";


export const useGetPosts = (page, category, price, participants, distance, sortingOption) => {
  const queryClient = useQueryClient();

  return useInfiniteQuery({
    queryKey: ['GET_INFINITE_POSTS', category, price, participants, distance, sortingOption],
    queryFn: ({ pageParam = 1 }) => getInfinitePosts(pageParam, category, price, participants, distance, sortingOption),
    getNextPageParam: (lastPage) => {
      if (lastPage && lastPage.currentPage < lastPage.totalPages) {
        return lastPage.currentPage + 1;
      }
      return null;
    },
    enabled: !!category || price || participants || distance || sortingOption,
    select: (data) => {
      const allDeals = data?.pages.flatMap((page) => page.deals) || [];
      const reversedDeals = allDeals.slice().reverse();
      return reversedDeals;
    },
    queryClient,
  });
};


// business posts
export const useGetBusinessPosts = (bsnId, category, price, participants, sortingOption) => {
  const queryClient = useQueryClient();

  return useInfiniteQuery({
    queryKey: ['GET_INFINITE_BUSINESS_POSTS', category, price, participants, sortingOption],
    queryFn: ({ pageParam = 1 }) => getInfiniteBusinessPosts(pageParam, bsnId, category, price, participants, sortingOption),
    getNextPageParam: (lastPage) => {
      if (lastPage && lastPage.currentPage < lastPage.totalPages) {
        return lastPage.currentPage + 1;
      }
      return null;
    },
    enabled: !!category || price || participants || sortingOption,
    select: (data) => {
      const allDeals = data?.pages.flatMap((page) => page.deals) || [];
      return allDeals;
    },
    queryClient,
  });
};

// Explore Results

export const useGetExploreResults = (keyword) => {
  const queryClient = useQueryClient();

  return useInfiniteQuery({
    queryKey: ['GET_EXPLORE_RESULTS', keyword],
    queryFn: ({ pageParam = 1 }) => getInfiniteExploreResults(pageParam, keyword),
    getNextPageParam: (lastPage) => {
      if (lastPage && lastPage.currentPage < lastPage.totalPages) {
        return lastPage.currentPage + 1;
      }
      return null;
    },
    enabled: !!keyword,
    select: (data) => {
      const allDeals = data?.pages.flatMap((page) => page.results
      ) || [];
      return allDeals;
    },
    queryClient,
  });

};
export const useDeleteNotification = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ isDeleteAll, userId, notificationType }) => deleteNotification(isDeleteAll, userId, notificationType),
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: ['GET_NOTIFICATIONS_TOTAL'],

      });
    },
  });
};
export const useGetNotifications = (userId) => {
  const queryClient = useQueryClient();

  return useInfiniteQuery({
    queryKey: ['GET_NOTIFICATIONS_TOTAL'],
    queryFn: ({ pageParam = 1 }) => getInfiniteNotifications(userId, pageParam),
    getNextPageParam: (lastPage) => {
      if (lastPage && lastPage.currentPage < lastPage.totalPages) {
        return lastPage.currentPage + 1;
      }
      return null;
    },
    select: (data) => {
      const allNotifications = data?.pages.flatMap((page) => page.notifications) || [];
      return allNotifications;
    },
    queryClient,
  });
};
// Non Search Explore Results
export const useGetExploreNonSearchResults = () => {
  const queryClient = useQueryClient();

  return useInfiniteQuery({
    queryKey: ['GET_EXPLORE_NON_SEARCH_RESULTS'],
    queryFn: ({ pageParam = 1 }) => getInfiniteExploreNonSearchResults(pageParam),
    getNextPageParam: (lastPage) => {
      if (lastPage && lastPage.currentPage < lastPage.totalPages) {
        return lastPage.currentPage + 1;
      }
      return null;
    },
    select: (data) => {
      const allDeals = data?.pages.flatMap((page) => page.results
      ) || [];
      return allDeals;
    },
    queryClient,
  });
};

// Explore Users By Distance
export const useGetCommunity = () => {
  const queryClient = useQueryClient();

  return useInfiniteQuery({
    queryKey: ['GET_COMMUNITY_TOTAL'],
    queryFn: ({ pageParam = 1 }) => getInfiniteUsers(pageParam),
    getNextPageParam: (lastPage) => {
      if (lastPage && lastPage.currentPage < lastPage.totalPages) {
        return lastPage.currentPage + 1;
      }
      return null;
    },

    select: (data) => {
      const allDeals = data?.pages.flatMap((page) => page.users) || [];
      return allDeals;
    },
    queryClient,
  });
};

// User Tasks
export const useGetFavorites = (userId) => {
  const queryClient = useQueryClient();

  return useInfiniteQuery({
    queryKey: ['GET_FAVORITE_TOTAL'],
    queryFn: ({ pageParam = 1 }) => getInfiniteFavorite(userId, pageParam),
    getNextPageParam: (lastPage) => {
      if (lastPage && lastPage.currentPage < lastPage.totalPages) {
        return lastPage.currentPage + 1;
      }
      return null;
    },
    select: (data) => {
      const allDeals = data?.pages.flatMap((page) => page.results) || [];
      return allDeals;
    },
    queryClient,
  });
};
// bsn reviews
export const useGetBsnReviews = (id, ratings) => {
  const queryClient = useQueryClient();

  return useInfiniteQuery({
    queryKey: ['GET_REVIEWS_ITEMS', ratings],
    queryFn: ({ pageParam = 1 }) => getInfiniteBsnReviews(id, ratings, pageParam),
    getNextPageParam: (lastPage) => {
      if (lastPage && lastPage.currentPage < lastPage.totalPages) {
        return lastPage.currentPage + 1;
      }
      return null;
    },
    enabled: !!ratings,
    select: (data) => {
      const allReviews = data?.pages.flatMap((page) => page.reviews) || [];
      return allReviews;
    },
    queryClient,
  });
};

export const useLikePost = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ dealId, userId, isLike }) => likePost(dealId, userId, isLike),
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: ['GET_INFINITE_POSTS'],

      });
      queryClient.invalidateQueries({
        queryKey: ['GET_INFINITE_BUSINESS_POSTS'],

      });
    },
  });
};

