import React, { useState, useEffect } from 'react'
import css from "./UserMobile.module.css"
import { useParams } from 'react-router-dom'
import axios from 'axios'
import Rodal from "rodal";
import "rodal/lib/rodal.css";
import Loader from "../../Components/Loader/Loader"
import {DEFAULTPROFILE} from "../../constants"

const UserMobile = () => {
    const { id } = useParams();
    const [modal, setModal] = useState({ visible: false });
    const [user, setUser] = useState();
    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const response = await axios.get(`https://synchronia.onrender.com/api/v1/users/${id}`);
                if (response.status === 200) {
                    setUser(response.data.user);
                }
            } catch (error) {
                console.log(error);
            }
        }
        fetchUserData();
    }, [id])

    if (!user) {
        return <Loader/>
    }
    const customStyles = {
        background: "var(--scaffoldcolor)",
        padding: "20px ",
        width: "90%",
        top: "-3rem",
        height: "80vh",
        maxWidth: "90%",
    };
    const handleYesClick = () => {
        window.location.href = "https://play.google.com/store/apps/details?id=com.synchronia.lifestyle&pcampaignid=web_share";
    };

    return (
        <div className={css.container}>
            {/* user deatils scroll */}
            <div className={css.sections}>
                {/* user details */}
                <div className={css.userProfile}>

                    <img src={user.profileimage!==""?user.profileimage:DEFAULTPROFILE} alt="profile" onClick={() => setModal({ visible: true, url: user.profileimage!==""?user.profileimage:DEFAULTPROFILE, isImage: true })} />
                    <div className={css.column}>
                        <div className={css.verified}>
                            <span>{user.username}</span>
                            {
                                user.isVerified ? <span class="material-symbols-outlined">
                                    verified
                                </span> : <span></span>
                            }

                        </div>
                        <span></span>
                        <span>{user.profession !== "" ? user.profession : "Recently Joined"}</span>
                        <span>{user.about !== "" ? user.about : "Hey there! am on Synchronia."}</span>
                    </div>

                    <button onClick={handleYesClick}>Download the app for more features</button>


                </div>
                {/* user friends and media */}
                <div className={css.columns}>
                </div>
            </div>
            <Rodal
                customStyles={customStyles}
                visible={modal.visible}
                onClose={() => {
                    setModal({ visible: false });
                }}
            >

                {
                    modal.isImage === true ? <img className={css.viewMediaImage} src={modal.url} alt="pic" /> : <span></span>
                }

            </Rodal>
        </div>
    )
}

export default UserMobile
