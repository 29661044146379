import { Navigate } from "react-router-dom";
import { auth } from "../firebase";
import { storage } from '../firebase';
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, sendPasswordResetEmail } from 'firebase/auth';
import axios from "axios";
import { ref, uploadBytes, getDownloadURL, updateMetadata } from "firebase/storage";
import { v4 } from 'uuid';
import { toast } from "react-toastify";


export const CreateAccount = async (email, password, username) => {
    try {
        let response = "";
        try {
            const dupEmailresponse = await axios.post(`https://synchronia.onrender.com/api/v1/new/user/email-check-exist`, { email: email })
            if (dupEmailresponse.status === 200) {

                const userCredentials = await createUserWithEmailAndPassword(auth, email, password);
                const { user } = userCredentials;
                let position;
                try {
                    position = await new Promise((resolve, reject) => {
                        navigator.geolocation.getCurrentPosition(resolve, reject);
                    });
                } catch (error) {
                    toast(error.message);
                    position = { coords: { latitude: -1.286389, longitude: 36.817223 } };
                }
                const requestBody = {
                    uid: user.uid,
                    username: username,
                    isOnline: true,
                    lastActiveTimeStamp: new Date().toISOString(),
                    profileimage: "",
                    profession: "Recently Joined",
                    email: user.email,
                    about: "Hey there am on Synchronia",
                    city: "User City",
                    latitude: position.coords.latitude,
                    longitude: position.coords.longitude,
                }
                response = await axios.post(`https://synchronia.onrender.com/api/v1/user/create-new-account/login`, requestBody)
                if (response.status === 200) {
                    const userData = response.data;
                    localStorage.setItem('userData', JSON.stringify(userData));
                    toast('Sign In with your credentials.')
                } else {
                    console.error('Failed to fetch user data');
                }
            }
        } catch (error) {
            toast('User with this email already exists')
        }

        return response;
    } catch (error) {
        console.log(error);
        return error;
    }
};


export const SignInToAccount = async (email, password) => {
    try {
        let response;
        const userCredentials = await signInWithEmailAndPassword(auth, email, password);
        const { user } = userCredentials;   
        try {
            const userDataString = localStorage.getItem('userData');
            if (userDataString) {
                response = await fetch(`https://synchronia.onrender.com/api/v1/login/user/details/${user.uid}`);
                if (response.ok) {
                    const userData = await response.json();
                    localStorage.setItem('userData', JSON.stringify(userData));
                } else {
                    console.error('Failed to fetch user data');
                }
            } else {
                response = await fetch(`https://synchronia.onrender.com/api/v1/login/user/details/${user.uid}`);
                if (response.ok) {
                    const userData = await response.json();
                    localStorage.setItem('userData', JSON.stringify(userData));
                } else {
                    console.error('Failed to fetch user data');
                }
            }
        } catch (error) {
            console.log(error);
        }
        console.log(response)
        return response;
    } catch (error) {
        console.log(error)
        return error;
    }
}


export const SendPasswordResetEmail = async (email) => {
    try {
        const success = await sendPasswordResetEmail(auth, email)
        toast('Check your email for a password reset')
        return success;
    } catch (error) {
        console.log(error)
        return error;
    }
}

export const logOut = async () => {
    try {
        await auth.signOut().then(() => {
            <Navigate to={'/'} />
        })
    } catch (error) {
        console.log(error)
        return error;
    }
}

// load offers

export const getInfinitePosts = async (pageParam, category, price, participants, distance, sortingOption) => {
    pageParam = pageParam || 1;
    distance = distance || 6000;
    try {
        let position;

        try {
            position = await new Promise((resolve, reject) => {
                navigator.geolocation.getCurrentPosition(resolve, reject);
            });
        } catch (error) {
            toast(error.message);
            position = { coords: { latitude: -1.286389, longitude: 36.817223 } };
        }

        let link = `https://synchronia.onrender.com/api/v1/deals/${distance}/${position.coords.latitude}/${position.coords.longitude}?page=${pageParam}`;
        if (category !== "All") {
            link += `&category=${category}`;
        }
        if (price !== "0") {
            link += `&price[lte]=${price}`;
        }
        if (participants !== "0") {
            link += `&noOfParticipants=${participants}`;
        }
        if (sortingOption !== 'Recommended First') {
            if (sortingOption === "Highest Price First") {
                link += '&sort=highest';
            } else {
                link += '&sort=lowest';
            }
        }

        const { data } = await axios.get(link);

        return data;
    } catch (error) {
        console.log(error);
        return {};
    }
};


// load offers specifc business
export const getInfiniteBusinessPosts = async (pageParam, bsnId, category, price, participants, sortingOption) => {
    pageParam = pageParam || 1;
    try {
        let link = `https://synchronia.onrender.com/api/v1/deals/?bsnId=${bsnId}&page=${pageParam}`;
        if (category !== "All") {
            link += `&category=${category}`;
        }
        if (price !== "0") {
            link += `&price[lte]=${price}`;
        }
        if (participants !== "0") {
            link += `&noOfParticipants=${participants}`;
        }
        if (sortingOption !== 'Recommended First') {
            if (sortingOption === "Highest Price First") {
                link += '&sort=highest';
            } else {
                link += '&sort=lowest';
            }
        }

        const { data } = await axios.get(link);
        return data;
    } catch (error) {
        console.log(error);
        return {};
    }
};

// load explore items

export const getInfiniteExploreResults = async (pageParam, keyword) => {
    pageParam = pageParam || 1;
    try {
        let link = `https://synchronia.onrender.com/api/v1/deals/explore-results?page=${pageParam}`;
        if (keyword !== "") {
            link += `&keyword=${keyword}`
        }
        const { data } = await axios.get(link);
        return data;
    } catch (error) {
        console.log(error);
        return {};
    }
};
// load explore items 
export const getInfiniteExploreNonSearchResults = async (pageParam) => {
    pageParam = pageParam || 1;
    try {
        let link = `https://synchronia.onrender.com/api/v1/deals/explore-results?page=${pageParam}`;
        const { data } = await axios.get(link);
        return data;
    } catch (error) {
        console.log(error);
        return {};
    }
};

// loadusersnearme
export const getInfiniteUsers = async (pageParam) => {
    pageParam = pageParam || 1;
    try {
        const position = await new Promise((resolve, reject) => {
            navigator.geolocation.getCurrentPosition(resolve, reject);
        });

        let link = `https://synchronia.onrender.com/api/v1/miniusermodel/distance/${position.coords.latitude}/${position.coords.longitude}?page=${pageParam}`;


        const { data } = await axios.get(link);
        return data;
    } catch (error) {
        console.log(error);
        return {};
    }
};

// loaduserFavourites
export const getInfiniteFavorite = async (userId, pageParam) => {
    pageParam = pageParam || 1;
    try {

        let link = `https://synchronia.onrender.com/api/v1/users/tasks/${userId}?page=${pageParam}`;
        const { data } = await axios.get(link);
        return data;
    } catch (error) {
        console.log(error);
        return {};
    }
};

// load messages
export const getInfiniteMessageChats = async (pageParam, userId, conversationId) => {
    pageParam = pageParam || 1;
    try {

        let link = `https://synchronia.onrender.com/api/v1/converstions/Messages/client/${conversationId}/${userId}/${pageParam}`;
        const { data } = await axios.get(link);
        const reversedResults = data.results.reverse();

        return reversedResults;
    } catch (error) {
        console.log(error);
        return {};
    }
};

export const deleteNotification = async (isDeleteAll, userId, notificationType) => {
    try {
        const requestBody = {
            isDeleteAll: isDeleteAll,
            userId: userId,
            notificationType: notificationType
        }

        const reponse = await axios.post(`https://synchronia.onrender.com/api/v1/user/Notifications/delete-notification`, requestBody);
        return reponse;
    } catch (error) {
        console.log(error);
        return error;
    }
};


// load notifications
export const getInfiniteNotifications = async (userId, pageParam) => {
    pageParam = pageParam || 1;
    try {

        let link = `https://synchronia.onrender.com/api/v1/users/notifications/${userId}?page=${pageParam}`;
        const { data } = await axios.get(link);
        return data;
    } catch (error) {
        console.log(error);
        return {};
    }
};

// load community user friends 
export const getInfiniteFriends = async (userId, pageParam) => {
    pageParam = pageParam || 1;
    try {
        let link = `https://synchronia.onrender.com/api/v1/users/${userId}/remaining-users?page=${pageParam}`;
        const { data } = await axios.get(link);
        return data;
    } catch (error) {
        console.log(error);
        return {};
    }
};

// getuserMedia
export const getInfiniteCollection = async (userId, pageParam) => {
    pageParam = pageParam || 1;
    try {
        let link = `https://synchronia.onrender.com/api/v1/users/web/media/${userId}`;
        const { data } = await axios.get(link);
        return data;
    } catch (error) {
        console.log(error);
        return {};
    }
};

// getBsnReviews
export const getInfiniteBsnReviews = async (id, ratings, pageParam) => {
    pageParam = pageParam || 1;
    try {
        let link = `https://synchronia.onrender.com/api/v1/business/${id}/reviews?rating=${ratings}`;
        const { data } = await axios.get(link);
        return data;
    } catch (error) {
        console.log(error);
        return {};
    }
};

// ============================== LIKE / UNLIKE POST
export const likePost = async (dealId, userId, isLike) => {
    try {
        const reponseBody = {
            isLike: isLike,
            dealId: dealId,
            userId: userId
        }
        await axios.post(`https://synchronia.onrender.com/api/v1/deals/like-reccomended-deal`, reponseBody)
    } catch (error) {
        console.log(error);
    }
}


export const updateUser = async (userId, uid, profileimage, image, username, profession, about) => {
    try {
        let imageUrl = profileimage
        if (image) {
            const uploadSingleFile = async (file, path) => {
                const storageRef = ref(storage, `${path}/${file.name}`);
                try {
                    const url = await getDownloadURL(storageRef);
                    return updateMetadata(storageRef, {
                        customMetadata: { updated: new Date().toString() },
                    })
                        .then(() => ({ url }))
                        .catch((error) => {
                            console.log(`Error updating file ${file.name}: ${error.message}`);
                        });
                } catch (error) {
                    const fileData = file;
                    return uploadBytes(storageRef, fileData)
                        .then(async (snapshot) => {
                            console.log(`File ${file.name} uploaded successfully.`);
                            const url = await getDownloadURL(snapshot.ref);
                            return {
                                public_Id: v4(),
                                url: url,
                            };
                        })
                        .catch((error) => {
                            console.log(`Error uploading file ${file.name}: ${error.message}`);
                        });
                }
            };
            const imageUrl1 = await uploadSingleFile(image, `UserProfiles/${uid}`)
            imageUrl = imageUrl1
        }
        const requestBody = {
            profileimage: imageUrl,
            username: username,
            profession: profession,
            about: about,
            phonenumber: ""
        }
        const response = await axios.post(`https://synchronia.onrender.com/api/v1/user/web-User/edit/${userId}/0`, requestBody);
        if (response.status === 200) {
            const userData = response.data.data;
            localStorage.setItem('userData', JSON.stringify(userData));
        }
        return response;
    } catch (error) {

    }
}