import React, { useState, useContext } from 'react'
import css from "./EditProfile.module.css"
import Rodal from "rodal";
import "rodal/lib/rodal.css";
import { AuthContext } from '../../AuthContext/AuthContext'
import { useNavigate } from 'react-router-dom';
import { DEFAULTPROFILE } from '../../constants';


const EditProfile = () => {
    const navigate = useNavigate();
    const { user, isLoading } = useContext(AuthContext);
    const [modal, setModal] = useState({ visible: false });
    if (isLoading) {
        return <span>loading...</span>
    }

    const customStyles = {
        background: "var(--scaffoldcolor)",
        padding: "20px ",
        width: "50%",
        top: "-3rem",
        height: "70vh",
        maxWidth: "50%",
    };
    return (
        <div className={css.container}>
            <div className={css.sections}>
                <img src={user && user.user.profileimage !== "" ? user.user.profileimage : DEFAULTPROFILE} alt="profile" onClick={() => setModal({ visible: true, image: user && user.user.profileimage !== "" ? user.user.profileimage : DEFAULTPROFILE })} />
                <div className={css.column}>
                    <div className={css.verified}>
                        <span>{user && user.user.username}</span>
                        {
                            user && user.user.isVerified ? <span class="material-symbols-outlined">
                                verified
                            </span> : <span></span>
                        }
                    </div>
                    <span>{user && user.user.email}</span>
                    <span>{user && user.user.profession !== "" ? user.user.profession : "Recently joined."}</span>
                    <span>{user && user.user.about !== "" ? user.user.about : "Hey there am on Synchronia"}</span>
                </div>
            </div>
            <Rodal
                customStyles={customStyles}
                visible={modal.visible}
                onClose={() => {
                    setModal({ visible: false });
                }}
            >

                <img src={modal.image} alt="profile" className='previewImage' />

            </Rodal>
            <button onClick={() => {
                navigate(`/profile/update/${user.user._id}/${user.user.uid}`)
            }}><i class="uil uil-edit"></i></button>
        </div>
    )
}

export default EditProfile
