import React, { useEffect, useState } from 'react'
import css from "./MobileFav.module.css"
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import Carosell from '../../Components/Carousell/carosell';
import Rodal from "rodal";
import "rodal/lib/rodal.css";
import FSizeCarosell from "../../Components/FullSizeCarosell/FSizeCarosell"
import Loader from '../../Components/Loader/Loader';

const MobileFav = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [modal, setModal] = useState({ visible: false });
    const [deal, setDeal] = useState(null);
    const [loading, setLoading] = useState(true);
    const cameraIcon = 'https://images.unsplash.com/photo-1495121553079-4c61bcce1894?w=400&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8Y2FtZXJhfGVufDB8fDB8fHww';
    const customStyles = {
        background: "var(--scaffoldcolor)",
        padding: "20px ",
        width: "100%",
        top: "-3rem",
        height: "80vh",
        maxWidth: "90%",
    };
    useEffect(() => {
        const FetchDeal = async () => {
            setLoading(true);
            try {
                const response = await axios.get(`https://synchronia.onrender.com/api/v1/deal/${id}`);
                if (response.status === 200) {
                    setDeal(response.data.deal);
                    setLoading(false)
                }
            } catch (error) {
                console.log(error)
            }
        }
        FetchDeal();
    }, [id])
    function redirectToWhatsAppAndMaps(latitude, longitude) {

        if (latitude && longitude) {
            const mapsUrl = `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`;
            window.open(mapsUrl, '_blank');
        }





    }
    const handleNavigate = (bsnId) => {
        navigate(`/business/${bsnId}`);
    }
    if (!deal) {
        return <Loader />

    }
    return (
        <div className={css.container}>
            <div className={css.sections}>
                {/* motivation */}
                <div className={css.header}>
                    <span><span class="material-symbols-outlined">
                        signature
                    </span></span>
                    <i class="uil uil-location-arrow" onClick={() => redirectToWhatsAppAndMaps(deal.dealLocationCoordinates.latitude, deal.dealLocationCoordinates.longitude)}></i>
                </div>

                {/* businessProfile */}
                <div className={css.business}>
                    <img src={deal && deal.logo} alt="profile" />
                    <div className={css.column}>
                        {/* info */}
                        <div className={css.rowOne}>
                            <span>{deal && deal.title}</span>
                            <i class="uil uil-ellipsis-h" onClick={() => handleNavigate(deal.bsnId)}></i>
                        </div>
                        {/* description */}
                        <div className={css.descContainer}>
                            {deal && deal.dealDescription}
                            <div className={css.rowTwo}></div>
                        </div>
                    </div>
                </div>
                {/* images */}
                <div className={css.business} >
                    <div className={css.column}>

                        {/* images */}
                        <div className={css.descContainer}>
                            <Carosell images={deal && deal.images} onClicked={() => setModal({ visible: true, images: deal.images })} />

                        </div>
                        <Rodal
                            customStyles={customStyles}
                            visible={modal.visible}
                            onClose={() => {
                                setModal({ visible: false });
                            }}
                        >

                            <FSizeCarosell images={modal.images ?? []} />

                        </Rodal>
                    </div>
                    <img src={cameraIcon} alt="profile" />
                </div>
            </div>

        </div>
    )
}

export default MobileFav
