import React, { useContext } from 'react'
import css from "./Homescreen.module.css"
import { Outlet } from 'react-router-dom'
import { page_links } from '../../Data'
import Sidebar from '../../Components/Sidebar/Sidebar'
import { AuthContext } from '../../AuthContext/AuthContext'
import Loader from '../../Components/Loader/Loader'


const Homescreen = () => {
    const { user, isLoading } = useContext(AuthContext);

    const initialuser = {
        profileimage: ""
    }
    if (user && user.user && user.user._id) {
        if (isLoading) {
            return <div className={css.container}>
                {/* SIDEBAR */}
                <div className={css.sidebar}>
                    <Sidebar page_links={page_links} user={user && user.user} isLoaded={false} />
                </div>
                {/* OUTLET */}
                <div className={css.outlet}>
                    <Loader />
                </div>

            </div>
        }

        return (
            <div className={css.container}>
                {/* SIDEBAR */}
                <div className={css.sidebar}>
                    <Sidebar page_links={page_links} user={user && user.user} isLoaded={true} />
                </div>
                {/* OUTLET */}
                <div className={css.outlet}>
                    <Outlet />
                </div>

            </div>
        )
    } else {
        if (isLoading) {
            return <div className={css.container}>
                {/* SIDEBAR */}
                <div className={css.sidebar}>
                    <Sidebar page_links={page_links} user={user && user.user} isLoaded={false} />
                </div>
                {/* OUTLET */}
                <div className={css.outlet}>
                    <Loader />
                </div>

            </div>
        }

        return (
            <div className={css.container}>
                {/* SIDEBAR */}
                <div className={css.sidebar}>
                    <Sidebar page_links={page_links} user={user && user.user} isLoaded={false} />
                </div>
                {/* OUTLET */}
                <div className={css.outlet}>
                    <Outlet />
                </div>

            </div>
        )
    }

}

export default Homescreen
