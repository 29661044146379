import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyBWi21tpfMPB-VdXtlROFI_l3oiTl09YyE",
    authDomain: "synchronia-ke.firebaseapp.com",
    projectId: "synchronia-ke",
    storageBucket: "synchronia-ke.appspot.com",
    messagingSenderId: "1061682959811",
    appId: "1:1061682959811:web:af099acde565541a069976",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);
export const auth = getAuth(app);
