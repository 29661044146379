import css from "./Support.module.css"

const Support = () => {
    const handleYesClick = () => {
        window.location.href = "https://play.google.com/store/apps/details?id=com.synchronia.lifestyle&pcampaignid=web_share";
    };
    const handleEmailClick = () => {
        const recipient = 'synchroniake@gmail.com';
        const subject = encodeURIComponent('Hey there');
        const body = encodeURIComponent('I am reaching out from Synchronia...');

        const mailtoLink = `mailto:${recipient}?subject=${subject}&body=${body}`;

        window.location.href = mailtoLink;
    };
    return (
        <div className={css.container}>
            <div className={css.chatArea}>
                <div className={css.row} onClick={() => {
                        const whatsappUrl = `https://wa.me/254799362341?text=${encodeURIComponent('Hello there am reaching out from synchronia...')}`;
                        window.open(whatsappUrl, '_blank');
                    }}>
                    <div className={css.whatsApp} >
                        <i class="uil uil-whatsapp"></i>
                    </div>
                    <span>Chat on Whatsapp</span>
                </div>
                <div className={css.row} onClick={handleYesClick}>
                    <div className={css.whatsApp} >
                        <i class="uil uil-download-alt"></i>
                    </div>
                    <span>Download Our App</span>
                </div>
                <div className={css.row} onClick={handleEmailClick}>
                    <div className={css.whatsApp} >
                        <i class="uil uil-envelope-edit"></i>
                    </div>
                    <span>Send us an email</span>
                </div>
            </div>
        </div>
    )
}

export default Support