import React, { useContext } from 'react';
import css from './Sidebar.module.css';
import { auth } from "../../firebase"
import { signOut } from 'firebase/auth'
import Logo from "../../Assets/logo2.png"
import { useNavigate } from 'react-router-dom';
import { DEFAULTPROFILE } from '../../constants';


const Sidebar = ({ page_links, user, isLoaded }) => {
    const navigate = useNavigate();
    const SignOut = async () => {
        try {

            await signOut(auth).then(
                navigate('/')
            );
        } catch (error) {

        }
    }
    return (
        <div className={css.sidebar}>
            <div className={css.heading}>
                <div className={css.logo}>
                    <img src={Logo} alt="logo" />
                    <h2 className={css.identifier}>Synchronia</h2>
                </div>
                <div className={css.links}>
                    {
                        page_links.map((link, index) => (
                            <div className={css.row} key={index} onClick={() => {
                                if (isLoaded) {
                                    navigate(`/${link.title.toLowerCase()}`)
                                }


                            }}>
                                {link.icon}
                                <span className={css.title}>{link.title}</span>
                            </div>
                        ))
                    }
                </div>
            </div>

            <div className={css.logOut}>
                <div className={css.profile} onClick={() => {
                    if (isLoaded) {
                        navigate('/profile');
                    }

                }}>
                    <img src={user && user.profileimage !== "" ? user.profileimage : DEFAULTPROFILE} alt="profile" />
                    <span className={css.identifier}>Profile</span>
                </div>
                <button onClick={SignOut}> <i class="uil uil-signout" >  </i><span className={css.identifier}>Log out</span></button>
            </div>
        </div>
    );
};

export default Sidebar;
