import React from 'react'
import css from "./landingwidget.module.css"
import Badge from "../../Assets/google-play-badge.png"
import LandingPageImage from "../../Assets/landingpage.jpg"


const landingwidget = () => {
    const handleYesClick = () => {
        window.location.href = "https://play.google.com/store/apps/details?id=com.synchronia.lifestyle&pcampaignid=web_share";
    };
    return (
        <div className={css.container}>
            <div className={css.sections}>
                {/* Tagline */}
                <div className={css.tagline}>
                    <div className={css.tag}>
                        <span>Next-Level<br />Business Services,<br />We got you.</span>
                        {/* border */}
                        <div className={css.tagcontainer}></div>
                    </div>
                    <span>Discover services, connect with others-all in one place.<br />Some some koo koo fun.</span>
                    <div className={css.playstoreBadge} onClick={() => handleYesClick()}>
                        <img src={Badge} alt="badge" />
                    </div>

                </div>
                {/* Image */}
                <div className={css.taglineImage}>
                    <img src={LandingPageImage} alt="landing" />
                </div>
            </div>

        </div>
    )
}

export default landingwidget
