import React, { useEffect, useState, useContext } from 'react'
import css from "./Community.module.css"
import { useNavigate } from 'react-router-dom'
import { useGetCommunity } from '../../React-Query/queriesAndMutations';
import { DEFAULTPROFILE } from "../../constants"
import { useInView } from 'react-intersection-observer';
import { AuthContext } from '../../AuthContext/AuthContext'
import Loader from '../../Components/Loader/Loader';

const Community = () => {
    const { user, isLoading } = useContext(AuthContext);
    const { ref, inView } = useInView();
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const deafultUrl = DEFAULTPROFILE;
    const navigate = useNavigate();
    const {
        data,
        error,
        isLoading: communityLoading,
        fetchNextPage,
        hasNextPage
    } = useGetCommunity();

    useEffect(() => {
        if (inView) fetchNextPage();
    }, [inView])

    useEffect(() => {
        const handleResize = () => {
            const newWidth = window.innerWidth;
            setWindowWidth(newWidth);
        };
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    if (isLoading) {
        return <Loader />
    }
    if (communityLoading) {
        return <Loader />
    }


    const handleNavigate = (index) => {
        if (windowWidth < 1200) {
            navigate(`/mobile/community/${index}`)
        } else {
            navigate(`/community/${index}`)
        }



    }

    return (
        <div className={css.container}>
            <div className={css.sections}>
                {data && Array.isArray(data) && data.map((object, index) => {
                    if (object._id !== user.user._id) {
                        return (
                            <div className={css.CommonCard} key={index} onClick={() => handleNavigate(object._id)}>
                                <img src={object.profileimage !== "" ? object.profileimage : deafultUrl} alt="profile" />
                                <div className={css.column}>
                                    <span>{object.username}</span>
                                    <span>{object.profession !== "" ? object.profession : "Recently Joined"}</span>
                                </div>
                            </div>
                        );
                    }

                })}
                {
                    hasNextPage ? <div className={css.loading} ref={ref}>
                        <span>Loading..</span>
                    </div> : <span></span>
                }
            </div>
        </div>
    )
}

export default Community
