import React, { useState, useEffect, useContext } from 'react'
import css from "./Favorite.module.css"
import FavCard from '../../Components/FavoriteCard/FavCard'
import Carosell from '../../Components/Carousell/carosell'
import { useGetFavorites } from '../../React-Query/queriesAndMutations';
import { useNavigate } from 'react-router-dom';
import Rodal from "rodal";
import "rodal/lib/rodal.css";
import FSizeCarosell from "../../Components/FullSizeCarosell/FSizeCarosell"
import Loader from '../../Components/Loader/Loader';
import { AuthContext } from '../../AuthContext/AuthContext';
import { useInView } from 'react-intersection-observer';


const Favourite = () => {
  const { user, isLoading } = useContext(AuthContext);
  const userId = user.user._id;
  const { ref, inView } = useInView();
  const navigate = useNavigate();
  const [deal, setDeal] = useState(null);
  const [modal, setModal] = useState({ visible: false });
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [favorite, setFavorite] = useState([]);
  const [keyword, setKeyword] = useState("")
  const cameraIcon = 'https://images.unsplash.com/photo-1495121553079-4c61bcce1894?w=400&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8Y2FtZXJhfGVufDB8fDB8fHww'; const customStyles = {
    background: "var(--scaffoldcolor)",
    padding: "20px ",
    width: "50%",
    top: "-3rem",
    height: "80vh",
    maxWidth: "50%",
  };
  const {
    data,
    error,
    isLoading: favLoading,
    fetchNextPage,
    hasNextPage
  } = useGetFavorites(userId, "");

  useEffect(() => {
    if (inView) fetchNextPage();
  }, [inView])

  const searchFavorite = (searchKeyword) => {
    if (data.length > 0) {
      const filtered = data.filter((item) => {
        return (

          item.title.toLowerCase().includes(searchKeyword.toLowerCase())
        );
      });
      setFavorite(filtered);
    }

  };

  const handleSearchInputChange = (e) => {
    const searchKeyword = e.target.value;
    if (searchKeyword.length > 0) {
      setKeyword(searchKeyword);
      searchFavorite(searchKeyword);
    } else {
      setKeyword("");
      setFavorite([]);
    }

  };

  useEffect(() => {
    const handleResize = () => {
      const newWidth = window.innerWidth;
      setWindowWidth(newWidth);
    };
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const handleNavigate = (bsnId) => {
    navigate(`/business/${bsnId}`);
  }
  function redirectToWhatsAppAndMaps(latitude, longitude) {

    if (latitude && longitude) {
      const mapsUrl = `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`;
      window.open(mapsUrl, '_blank');
    }





  }
  if (isLoading) {
    return <Loader />
  }

  return (
    <div className={css.container}>
      {
        deal ? <div className={css.cards}>
          <div className={css.sections}>
            {/* motivation */}
            <div className={css.header}>
              <span><span class="material-symbols-outlined">
                signature
              </span></span>
              <i class="uil uil-location-arrow" onClick={() => redirectToWhatsAppAndMaps(deal && deal.dealLocationCoordinates.latitude, deal && deal.dealLocationCoordinates.longitude)}></i>
            </div>

            {/* businessProfile */}
            <div className={css.business}>
              <img src={deal && deal.logo} alt="profile" />
              <div className={css.column}>
                {/* info */}
                <div className={css.rowOne}>
                  <span>{deal && deal.title}</span>
                  <i class="uil uil-ellipsis-h" onClick={() => handleNavigate(deal.bsnId)}></i>
                </div>
                {/* description */}
                <div className={css.descContainer}>
                  {deal && deal.dealDescription}
                  <div className={css.rowTwo}></div>
                </div>
              </div>
            </div>
            {/* images */}
            <div className={css.business}>

              <div className={css.column}>

                {/* images */}
                <div className={css.descContainer} >
                  <Carosell images={deal.images} onClicked={() => setModal({ visible: true, images: deal.images })} />
                  <div className={css.rowTwo}></div>
                </div>
                <Rodal
                  customStyles={customStyles}
                  visible={modal.visible}
                  onClose={() => {
                    setModal({ visible: false });
                  }}
                >

                  <FSizeCarosell images={modal.images ?? []} />

                </Rodal>
              </div>
              <img src={cameraIcon} alt="profile" />


            </div>
          </div>
        </div> : <div className={css.loadingarea}>
          <span>{data && data.length === 0 ? "Your favorites businesses posts will apper here" : "Click a favorite on the right to view it"}</span>
        </div>
      }


      <div className={css.leftSide}>
        {/* greetings */}
        <div className={css.row}>
          <i class="uil uil-search"></i>
          <input type="text" placeholder='Search...' value={keyword} onChange={handleSearchInputChange} />
          <span>{data && data.length}</span>
        </div>
        {/* favorite_lists */}
        <div className={css.favlists}>
          {
            favorite.length === 0 ?
              data && data.map((favorite, index) => {
                return <div className={css.favCardItem} key={index} onClick={() => {
                  if (windowWidth < 1200) {
                    navigate(`/favorites/${favorite._id}`)
                  } else {
                    setDeal(favorite)
                  }
                }}>
                  <FavCard data={favorite} />
                </div>
              }) : favorite && favorite.map((favorite, index) => {
                return <div className={css.favCardItem} key={index} onClick={() => {
                  if (windowWidth < 1200) {
                    navigate(`/favorites/${favorite._id}`)
                  } else {
                    setDeal(favorite)
                  }
                }}>
                  <FavCard data={favorite} />
                </div>
              })
          }
          {
            hasNextPage ? <div className={css.loading} ref={ref}>
              <span>Loading..</span>
            </div> : <span></span>
          }
        </div>
      </div>
    </div>
  )
}

export default Favourite
