import React from 'react';
import { useForm } from 'react-hook-form';
import css from './Footer.module.css';
import { toast } from 'react-toastify';
import axios from 'axios';
import Badge from "../../Assets/google-play-badge.png"

const Footer = () => {
    const { register, handleSubmit, formState: { errors } ,reset } = useForm();
    const handleYesClick = () => {
        window.location.href = "https://play.google.com/store/apps/details?id=com.synchronia.lifestyle&pcampaignid=web_share";
    };
    const onSubmit = async (data) => {
        try {

            const requestBody = {
                username: data.username,
                email: data.email,
                message: data.message,
            };
            const response = await axios.post(
                "https://synchronia.onrender.com/api/v1/synchronia/contact-us/message",
                requestBody
            );
            console.log(response);
            if (response.status === 200) {
                toast('Thankyou for the feedback.');
                reset();
            }
        } catch (error) {
            toast(error.message);
            reset();
        }
    };


    return (
        <div className={css.container}>
            <h2>CONTACT US</h2>
            <div className={css.sections}>
           
                {/* copyright */}
                
                <div className={css.copyRight}>
                <div className={css.playstoreBadge} onClick={() => handleYesClick()}>
                        <img src={Badge} alt="badge" />
                    </div>
                    <div className={css.row}>
                        <i className="uil uil-copyright"></i>
                        <span> Synchronia 2024</span>
                    </div>
                    <div className={css.socails}>
                        <i className="uil uil-youtube" onClick={() => {
                            window.location.href =
                                "https://www.youtube.com/channel/UC7BJAaOji6daTEc4hCwxTkg";
                        }}></i>
                        <i className="uil uil-instagram-alt"
                            onClick={() => {
                                window.location.href =
                                    "https://www.instagram.com/synchroniake/";
                            }}
                        ></i>
                        <i className="uil uil-facebook" onClick={() => {
                            window.location.href =
                                "https://www.facebook.com/profile.php?id=61552953732541";
                        }}></i>
                    </div>
                </div>
                {/* contact Us */}
                <div className={css.contactUs}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <input type="text" placeholder="Username" {...register('username', { required: true, minLength: 6 })} />
                        {errors.username && <span>Username should be at least 6 characters</span>}

                        <input type="email" placeholder="Email" {...register('email', { required: true, pattern: /^\S+@\S+$/i })} />
                        {errors.email && <span>Enter a valid email address</span>}

                        <textarea placeholder="Message" {...register('message', { required: true })}></textarea>
                        {errors.message && <span>This field is required</span>}

                        <button type="submit">Submit</button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Footer;
